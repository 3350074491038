import React from 'react'

const Subscribe = () => {
    return (
        <section className="subscribe">
            <div className="container">
                <h5 className="subsribe-title text-color-white">
                    NOTE:
                </h5>
                <p className="subscribe-text text-color-white">
                    There is no gambling or betting with real money on this site. All games are free social casino games, and no real prizes or payouts will be awarded. socialgame333.com is solely a portal for free access to social games. Our service is primarily about entertainment. Have fun and play as much as you want. Remember, winning in these games does not mean that you will have any increased chances of winning in future real-money games.
                </p>
                <p className="subscribe-text text-color-white">
                    To play, you must be at least 18 years old.
                </p>
                <form action="" className="subscribe-form">
                    <h4 className="form-title text-center mb-2">
                        SUBSCRIBE TO OUR NEWSLETTER!
                    </h4>
                    <p className="form-text text-center">
                        Never miss the latest news about our brands and social casinos.
                    </p>
                    <div className="input-wrapper d-flex align-items-stretch justify-content-center">
                        <input type="email" name="email" id="email" placeholder="Enter your email" />
                        <button type="reset">
                            Subscribe
                        </button>
                    </div>

                </form>
            </div>
        </section>
    )
}

export default Subscribe