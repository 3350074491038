import React from 'react'

const Game = () => {
    return (
        <section className="game-frame">
            <div className="container">
                <div className="inner-wrapper">
                    <div className="title-block">
                        <h2>
                            JackPot Slot Machine
                        </h2>
                    </div>
                    <div className="modal-window">
                        <iframe title="Aztec Magic Megaways" className="singlegame-iframe" loading="lazy" frameBorder="0" scrolling="no" allowFullScreen="" src="https://socialgame333.com/game" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" data-astro-cid-zfdjajio=""></iframe>
                    </div>
                    <div className="risk-info-block">
                        <p>
                        <strong>Please read before you play!</strong> All games on this site are 100% free, but even though our games are NOT gambling games, we require that our visitors and members are at least 18 years old. If you are 18 years or older, you can enjoy our social games for free and play for fun. However, please note that your chances of winning on a gambling website will not increase even if you practice by playing the free games on our site.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Game